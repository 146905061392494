import { UserHelper, PermissionHelper } from '../../helpers/Index';
import _ from 'lodash';

export const constants = {
  setUser: 'SET_USER_FROM_ASYNC_STORAGE',
  loginRequest: 'USER_LOGIN_REQUEST',
  loginSuccess: 'USER_LOGIN_SUCCESS',
  loginFail: 'USER_LOGIN_FAIL',
  reAuthenticationRequest: 'RE_AUTHENTICATION_REQUEST',
  reAuthenticationSuccess: 'RE_AUTHENTICATION_SUCCESS',
  reAuthenticationFail: ' RE_AUTHENTICATION_FAIL',
  setCurrentActiveMerchant: 'SET_CURRENT_ACTIVE_MERCHANT',
  resetActiveMerchant: 'RESET_ACTIVE_MERCHANT',
  getCurrentActiveMerchant: 'GET_CURRENT_ACTIVE_MERCHANT',
  setPrevState: 'SET_PREV_STATE',
  resetPrevState: 'RESET_PREV_STATE',
  setUserLocationCoordinate: 'SET_USER_LOCATION_COORDINATE',
  uploadOcrSuccess: 'UPLOAD_OCR_SUCCESS',
  uploadOcrFail: 'UPLOAD_OCR_SUCCESS',
  registerNewUserRequest: 'REGISTER_NEW_USER_REQUEST',
  registerNewUserSuccess: 'REGISTER_NEW_USER_SUCCESS',
  registerNewUserFail: 'REGISTER_NEW_USER_FAIL',
  setIdentityImage: 'SET_IDENTITY_IMAGE',
  setSelfieImage: 'SET_SELFIE_IMAGE',
  setFaceSimilarity: 'SET_FACE_SIMILARITY',
  userCovidTestRequest: 'USER_COVID_TEST_REQUEST',
  userCovidTestSuccess: 'USER_COVID_TEST_SUCCESS',
  userCovidTestFail: 'USER_COVID_TEST_FAIL',
};

const localStorageUserData = UserHelper.getCurrentUserInformation();

const initState = {
  fetching: false,
  isLoggedIn: false,
  loginError: false,
  user_id: null,
  email: null,
  phone: null,
  password: null,
  fit_point: null,
  token: {
    access_token: null,
  },
  user_address: null,
  image: null,
  gender: null,
  activeMerchant:
    localStorageUserData && localStorageUserData.user_merchants.length > 0
      ? localStorageUserData.user_merchants[0].merchant_id
      : null,
  prevStateValue: {},
  coordinate: {},
  ocrResult: null,
  setIdentityImage: false,
  setSelfieImage: false,
  faceSimilarity: 0,
  userCovidTestResult: {},
};

export default function usersReducer(state = initState, action) {
  const { type, payload } = action;
  let currentPageAccess = '';
  let defaultCallBack = '';

  switch (type) {
    case constants.loginRequest:
      return { ...state, fetching: true };
    case constants.loginSuccess:
      currentPageAccess = payload.page_access;
      defaultCallBack = PermissionHelper.getCallBackURL(currentPageAccess);

      localStorage.setItem('is_loggedIn', 'true');
      localStorage.setItem(
        'user_info',
        JSON.stringify({
          ...state,
          ...payload,
          fetching: false,
          isLoggedIn: true,
          loginError: false,
          isResellerLogin: _.lowerCase(payload.role_name) === 'reseller',
          callBackURL: defaultCallBack,
        }),
      );
      return {
        ...state,
        ...payload,
        fetching: false,
        isLoggedIn: true,
      };
    case constants.loginFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.reAuthenticationRequest:
      return { ...state, fetching: true };
    case constants.reAuthenticationSuccess:
      localStorage.setItem('is_loggedIn', 'true');
      localStorage.setItem('user_info', UserHelper.reUpdateUserAuthentication(payload));
      return {
        ...state,
        ...payload,
        fetching: false,
        isLoggedIn: true,
      };
    case constants.reAuthenticationFail:
      return { ...state, fetching: false, loginError: payload };
    case constants.setCurrentActiveMerchant:
      return { ...state, fetching: false, activeMerchant: payload };
    case constants.resetActiveMerchant:
      return { ...state, fetching: false, activeMerchant: null };
    case constants.setPrevState:
      return { ...state, prevStateValue: payload };
    case constants.resetPrevState:
      return { ...state, prevStateValue: {} };
    case constants.setUserLocationCoordinate:
      return { ...state, coordinate: payload };

    case constants.uploadOcrSuccess:
      return { ...state, ocrResult: payload };
    case constants.uploadOcrFail:
      return { ...state, ocrResult: null };

    case constants.registerNewUserRequest:
      return { ...state, fetching: true };
    case constants.registerNewUserSuccess:
      return {
        ...state,
        fetching: false,
      };
    case constants.registerNewUserFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    case constants.setIdentityImage:
      return {
        ...state,
        setIdentityImage: payload,
      };

    case constants.setSelfieImage:
      return {
        ...state,
        setSelfieImage: payload,
      };

    case constants.setFaceSimilarity:
      return {
        ...state,
        faceSimilarity: payload,
      };

    case constants.userCovidTestRequest:
      return { ...state, fetching: true };
    case constants.userCovidTestSuccess:
      return {
        ...state,
        fetching: false,
        userCovidTestResult: payload,
      };
    case constants.userCovidTestFail:
      return {
        ...state,
        fetching: false,
        errorFetch: true,
        errorMessage: payload,
      };

    default:
      return state;
  }
}
