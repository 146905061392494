import { CommonHelper, MasterDataHelper } from './Index';
import _ from 'lodash';
import { UserHelper } from './UserHelper';

const PermissionModule = {
  // Please order this alphabetically to help maintenance
  Eat: 'EATS',
  Marketing: 'MARKETING',
  MasterData: 'MASTERDATA',
  Medic: 'MEDIC',
  Memberships: 'MEMBERSHIPS',
  Move: 'MOVE',
  Orders: 'ORDERS',
  Report: 'REPORT',
  Setting: 'SETTING',
  UserManagement: 'USERMANAGEMENTS',
};

const PermissionPage = {
  // Please group them by the module and order alphabetically to help maintenance

  // #region Common Page
  List: 'LIST',
  // #endregion

  // #region Eat
  Food: 'FOOD',
  FoodList: 'FOOD_LIST',
  InPatient: 'IN_PATIENT',
  MealDelivery: 'MEAL_DELIVERY',
  // #endregion

  // #region Marketing
  Campaign: 'CAMPAIGN',
  Feeds: 'FEEDS',
  Inbox: 'INBOX',
  PopUpScreen: 'POPUPSCREEN',
  PromoCode: 'PROMO_CODE',
  Segment: 'SEGMENT',
  TnCSection: 'TNCSECTION',
  // #endregion

  // #region Master Data
  Branch: 'BRANCH',
  Company: 'COMPANY',
  Merchant: 'MERCHANT',
  ProductCategory: 'PRODUCT_CATEGORY',
  ProductWholesale: 'PRODUCT_WHOLESALE',
  // #endregion

  // #region Medic
  ScheduleMedic: 'SCHEDULE',
  // #endregion

  // #region Memberships
  Purchase: 'PURCHASE',
  Request: 'REQUEST',
  // #endregion

  // #region Move
  Schedule: 'SCHEDULE',
  // #endregion

  // #region Orders
  Handover: 'HANDOVER',
  PurchaseOrder: 'PURCHASE_ORDER',
  Shipment: 'SHIPMENT',
  Summary: 'SUMMARY',
  // #endregion

  // #region Report
  Conduct: 'CONDUCT_PT',
  Daily: 'DAILY_SALES',
  // #endregion

  // #region User Management
  RolesAndPermissions: 'ROLESPERMISSIONS',
  Users: 'USERS',
  // #endregion
};

const PermissionAccess = {
  View: 'VIEW',
  Add: 'ADD',
  Update: 'UPDATE',
  Delete: 'DELETE',
};

const permissionAccessPurchaseOrder = {
  // #region sales
  CreatePurchaseOrder: 'CREATE_PURCHASE_ORDER',
  UpdatePurchaseOrder: 'UPDATE_PURCHASE_ORDER',
  DeletePurchaseOrder: 'DELETE_PURCHASE_ORDER',
  // #endregion
  // #region warehouse
  UpdatePurchaseOrderQty: 'UPDATE_PURCHASE_ORDER_QTY',
  UpdatePurchaseOrderNote: 'UPDATE_PURCHASE_ORDER_NOTE',
  PrintProformaInvoice: 'PRINT_PROFORMA_INVOICE',
  PrintDeliverySlip: 'PRINT_DELIVERY_SLIP',
  UpdateShipment: 'UPDATE_SHIPMENT',
  UploadShippingEvidence: 'UPLOAD_SHIPPING_EVIDENCE',
  UpdateDelivered: 'UPDATE_DELIVERED',
  UploadDeliveredEvidence: 'UPLOAD_DELIVERED_EVIDENCE',
  // #endregion
  // #region finance
  PrintInvoice: 'PRINT_INVOICE',
  CreateProformaInvoice: 'CREATE_PROFORMA_INVOICE',
  CreateInvoice: 'CREATE_INVOICE',
  UploadInvoice: 'UPLOAD_INVOICE',
  SetDuDate: 'SET_DU_DATE',
  UpdatePaymentStatus: 'UPDATE_PAYMENT_STATUS',
  UploadInvoiceEvidence: 'UPLOAD_INVOICE_EVIDENCE',
  UpdatePaidStatus: 'UPDATE_PAID_STATUS',
  UploadPaymentReceipt: 'UPLOAD_PAYMENT_RECEIPT',
  // #nedregion
};

const PermissionHelper = {
  generatePermissionGroup(dataArray) {
    const converted = [];

    dataArray.forEach(item => {
      let convertedChildren = [];
      let convertedAccess = [];

      if (item.children.length > 0) {
        convertedChildren = PermissionHelper.generatePermissionGroupChile(item.children);
      }

      if (item.access.length > 0 && item.access.length > 1) {
        convertedAccess = PermissionHelper.generatePermissionGroupAccess(item.access, null);
      } else {
        const param = {
          name: CommonHelper.renameRemoveCharacter(item.access[0].toLowerCase(), '_'),
          value: item.access[0],
          checked: true,
        };
        convertedAccess.push(param);
      }
      const param = {
        name: CommonHelper.renameRemoveCharacter(item.value.toLowerCase(), '_'),
        value: item.value,
        access: convertedAccess,
        children: convertedChildren,
      };

      converted.push(param);
    });

    const result = converted;
    return result;
  },
  generatePermissionGroupChile(dataArray) {
    const converted = [];

    dataArray.forEach(item => {
      let convertedAccess = [];

      if (item.access.length > 0) {
        convertedAccess = PermissionHelper.generatePermissionGroupAccess(item.access, null);
      }
      const title = item.value.split('.');

      const param = {
        name: CommonHelper.renameRemoveCharacter(title[1].toLowerCase(), '_'),
        value: item.value,
        access: convertedAccess,
      };

      converted.push(param);
    });
    const result = converted;

    return result;
  },
  generatePermissionGroupAccess(dataArray, currentConverted) {
    const converted = currentConverted || [];
    const defaultAccess = ['VIEW', 'ADD', 'UPDATE', 'DELETE'];
    let validValue = false;

    dataArray.forEach(item => {
      const value = item;

      if (defaultAccess[converted.length] === value) {
        const param = {
          name: CommonHelper.renameRemoveCharacter(value.toLowerCase(), '_'),
          value,
          checked: true,
        };

        converted.push(param);
        validValue = true;
      }
    });

    if (!validValue) {
      const value = defaultAccess[converted.length];
      const param = {
        name: CommonHelper.renameRemoveCharacter(value.toLowerCase(), '_'),
        value,
        checked: false,
      };

      converted.push(param);
    }

    if (converted.length < 4) {
      PermissionHelper.generatePermissionGroupAccess(dataArray, converted);
    }

    const result = converted;

    return result;
  },
  generateAssignPermissionRole(dataArray) {
    const converted = [];

    dataArray.forEach(item => {
      if (item.children.length > 0) {
        item.children.forEach(itemChile => {
          if (itemChile.access.length > 0) {
            itemChile.access.forEach(itemAccess => {
              if (itemAccess.checked) {
                const parentName = itemChile.value;
                const currentPermission = `${parentName}.${itemAccess.value}`;
                converted.push(currentPermission);
              }
            });
          }
        });
      } else if (item.access.length > 0) {
        item.access.forEach(itemAccess => {
          if (itemAccess.checked) {
            const parentName = item.value;
            const currentPermission = `${parentName}.${itemAccess.value}`;
            converted.push(currentPermission);
          }
        });
      }
    });

    const result = converted;
    return result;
  },
  generateAssignNewPermissionRole(dataArray) {
    const converted = [];

    dataArray.forEach(item => {
      converted.push(item.value);
    });

    const result = converted;
    return result;
  },
  getUrlAccess(key, tree) {
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      const hasAccess = node.url.toLowerCase() === key.toLowerCase();
      if (hasAccess) {
        return node.permissions;
      }

      if (node.children && node.children.length > 0) {
        for (let j = 0; j < node.children.length; j += 1) {
          const childAccess = this.getUrlAccess(key, node.children);
          if (childAccess) {
            return childAccess;
          }
        }
      }
    }
    return null;
  },
  generateTabPermissionMembershipRequest(pathUrl) {
    const defaultAccess = ['VIEW', 'ADD', 'UPDATE', 'DELETE'];
    let result = CommonHelper.objectCloning(MasterDataHelper.membershipRequestTypeStatus);
    const currentUser = UserHelper.getCurrentUserInformation();
    const access = currentUser.page_access;
    const userAccess = this.getUrlAccess(pathUrl, access);

    if (!_.isEmpty(userAccess)) {
      let binaryCode = '';
      defaultAccess.forEach(item => {
        binaryCode += (userAccess.indexOf(item) > -1 ? 1 : 0).toString();
      });

      switch (binaryCode) {
        case '1010':
          result = result.slice(1, result.length);
          break;
        default:
          break;
      }
    }

    return result;
  },
  checkAuthorizeAccessUrl(pathUrl) {
    // const defaultAccess = ['VIEW', 'ADD', 'UPDATE', 'DELETE'];
    const currentUser = UserHelper.getCurrentUserInformation();
    const access = currentUser.page_access;
    const userAccess = this.getUrlAccess(pathUrl, access);
    let result = false;

    if (!_.isEmpty(userAccess)) {
      result = true;
    }

    return result;
  },
  checkUserHasPermission(module, page, requiredPermission) {
    const currentUser = UserHelper.getCurrentUserInformation();
    if (!currentUser) {
      return false;
    }

    const pagePermission = `${module}.${page}.${requiredPermission}`;
    const hasAccess =
      currentUser.user_permissions.findIndex(
        x => x.toUpperCase() === pagePermission.toUpperCase(),
      ) !== -1;

    return hasAccess;
  },
  getUnauthorizedRedirectPath() {
    const currentUser = UserHelper.getCurrentUserInformation();
    const currentPageAccess = UserHelper.getUserPagesAccess();
    const defaultCallBack = PermissionHelper.getCallBackURL(currentPageAccess) || '/';

    const redirectPath = currentUser ? defaultCallBack : '/sign-in';

    return redirectPath;
  },
  getCallBackURL(tree) {
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      const firstURL = node.url.toLowerCase();
      if (firstURL && firstURL !== '#') {
        return firstURL;
      }

      if (node.children && node.children.length > 0) {
        for (let j = 0; j < node.children.length; j += 1) {
          const childAccess = this.getCallBackURL(node.children);
          if (childAccess) {
            return childAccess;
          }
        }
      }
    }

    return null;
  },
  generatePermissionPurchaseOrder(pathUrl) {
    // const defaultAccess = ['VIEW', 'ADD', 'UPDATE', 'DELETE'];
    const result = CommonHelper.objectCloning(MasterDataHelper.purchaseOrderTab);
    const currentUser = UserHelper.getCurrentUserInformation();
    const access = currentUser.page_access;
    const userAccess = this.getUrlAccess(pathUrl, access);

    if (!_.isEmpty(userAccess)) {
      //   let binaryCode = '';
      //   defaultAccess.forEach(item => {
      //     binaryCode += (userAccess.indexOf(item) > -1 ? 1 : 0).toString();
      //   });
      //   switch (binaryCode) {
      //     case '1010':
      //       result = result.slice(1, result.length);
      //       break;
      //     default:
      //       break;
      //   }
    }

    return result;
  },
};

export {
  PermissionHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  permissionAccessPurchaseOrder,
};
