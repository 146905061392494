import _ from 'lodash';
import FileHelper from './FileHelper';

const regexKey = {
  email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
  // phone: /^(^\+62\s?|^0)(\d{3,4}?){2}\d{3,4}$/i,
  phone: /^([0][8]|[6][2][8])(\d{2,3}?){2}\d{3,4}$/i,
  phoneLocal: /^([0][2])(\d{2,3}?){2}\d{3,4}$/i,
  password: /^(?=[\S]{6,}$)[\S]*/,
  numberCapacity: /^(?!\s*$)([1-9][0-9][0-9]?|)$/,
  numberPercentage: /^(?!\s*$)([0-9][0-9]?|100)$/,
  stringAndFullStop: /^(?!\s*$)[A-Za-z]*[.]*(?!\s*$)[A-Za-z-_]*$/,
  coordinates: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
  word: /\S+/gm,
};

const ValidationHelper = {
  validateEmail(email) {
    let result = false;
    if (email.match(regexKey.email)) result = true;
    else result = false;
    return result;
  },
  validatePhone(phone) {
    let result = false;
    if (phone.match(regexKey.phone)) result = true;
    else result = false;
    return result;
  },
  validatePhoneLocal(phone) {
    let result = false;
    if (phone.match(regexKey.phoneLocal)) result = true;
    else result = false;
    return result;
  },
  validatePassword(password) {
    let result = false;
    if (password.match(regexKey.password)) result = true;
    else result = false;
    return result;
  },
  validateNumberCapacity(input) {
    let result = false;
    if (input.match(regexKey.numberCapacity)) result = true;
    else result = false;
    return result;
  },
  validateNumberPercentage(input) {
    let result = false;

    if (input.match(regexKey.numberPercentage)) result = true;
    else result = false;
    return result;
  },
  validatePermissionName(input) {
    let result = false;
    if (input.match(regexKey.stringAndFullStop)) result = true;
    else result = false;
    return result;
  },
  validateCoordinates(input) {
    let result = false;
    if (input.match(regexKey.coordinates)) result = true;
    else result = false;
    return result;
  },
  validateWord(input) {
    const totalLength = input.match(regexKey.word);
    const result = !_.isEmpty(totalLength) ? totalLength.length : 0;
    return result;
  },
  validationSizeFile(value, maxSize = 0.1) {
    const size = FileHelper.getStringSize(value);
    const sizeToMb = size / 1024 / 1024;

    const result = sizeToMb < maxSize;

    return result;
  },
};

export default ValidationHelper;
