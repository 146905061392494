/* eslint-disable no-plusplus */
import { Images } from '../assets/Index';
import CommonHelper from './CommonHelper';

const membershipActionCode = {
  changePayment: 'changePayment',
  freeze: 'freeze',
  unfreeze: 'unfreeze',
  stopRecurring: 'stopRecurring',
  renew: 'renew',
  viewDetails: 'viewDetails',
};

const MasterDataHelper = {
  repeatTimeData: [
    { value: null, name: 'Select Duration' },
    { value: 30, name: '30 minutes' },
    { value: 45, name: '45 minutes' },
    { value: 60, name: '1 Hour' },
    { value: 120, name: '2 Hour' },
    { value: 0, name: '1 per Day' },
  ],
  repeatDayData: [
    { value: 'sunday', name: 'Sunday', checked: false },
    { value: 'monday', name: 'Monday', checked: false },
    { value: 'tuesday', name: 'Tuesday', checked: false },
    { value: 'wednesday', name: 'Wednesday', checked: false },
    { value: 'thursday', name: 'Thursday', checked: false },
    { value: 'friday', name: 'Friday', checked: false },
    { value: 'saturday', name: 'Saturday', checked: false },
  ],
  repeatWeekData: [
    { value: null, name: 'Select Week' },
    { value: 7, name: '1 Week' },
    { value: 14, name: '2 Weeks' },
    { value: 21, name: '3 Weeks' },
    { value: 28, name: '4 Weeks' },
    { value: 30, name: '1 Month' },
  ],
  radioButtonShiftData: [
    { value: 0, name: 'In Shift' },
    { value: 1, name: 'Out Shift' },
  ],
  radioButtonTrueFalse: [
    { value: 1, name: 'Yes' },
    { value: 0, name: 'No' },
  ],
  statusGlobal: [
    { value: 10, name: 'Active' },
    { value: 0, name: 'Inactive' },
    { value: -1, name: 'Deleted' },
  ],
  statusGlobalWithoutDeleted: [
    { value: 10, name: 'Active' },
    { value: 0, name: 'Inactive' },
  ],
  statusSchedule: [
    { value: 10, name: 'Active' },
    { value: 3, name: 'Waiting' },
    { value: 0, name: 'Inactive' },
    { value: -1, name: 'Deleted' },
  ],
  statusPointBooster: [
    { value: 6, name: 'Freeze' },
    { value: 3, name: 'Stopped' },
    { value: 10, name: 'Active' },
    { value: 0, name: 'Inactive' },
  ],
  statusPayment: [
    { value: -1, name: 'Cancelled' },
    { value: 0, name: 'Awaiting Token' },
    { value: 1, name: 'Token Generated' },
    { value: 2, name: 'Virtual Bank Account Generated' },
    { value: 3, name: 'Credit Card Charged' },
    { value: 4, name: 'Expired' },
    { value: 5, name: 'Pending' },
    { value: 6, name: 'Declined' },
    { value: 7, name: 'Suspended' },
    { value: 8, name: 'Confirmation' },
    { value: 9, name: 'Refund' },
    { value: 10, name: 'Completed' },
    { value: 11, name: 'Settlement' },
    { value: 12, name: 'GoPay QR And Deeplink Generated' },
  ],
  statusPaymentOptionV2: [
    { value: 'expired', name: 'Expired' },
    { value: 'pending', name: 'Pending' },
    { value: 'completed', name: 'Completed' },
  ],
  limitPages: [
    { value: 10, name: '10' },
    { value: 20, name: '20' },
    { value: 30, name: '30' },
    { value: 40, name: '40' },
  ],
  productType: [
    { value: null, name: 'Select Product Type' },
    { value: 'membership_package', name: 'MembershipPackage' },
    { value: 'top_up_points', name: 'TopUpPoint' },
    { value: 'point_booster', name: 'PointBooster' },
    { value: 'workout_session', name: 'WorkoutSession' },
    { value: 'coach_session', name: 'CoachSession' },
    // { value: 'foodlist', name: 'FoodList' },
    { value: 'food', name: 'Food' },
    // { value: 'food_extras', name: 'FoodExtras' },
    // { value: 'event', name: 'Event' },
    { value: 'event_ticket', name: 'EventTicket' },
    { value: 'retail', name: 'Retail' },
  ],
  nationality: [
    { name: 'WNI', value: 'WNI' },
    { name: 'WNA', value: 'WNA' },
  ],
  gender: [
    { value: 1, name: 'Male' },
    { value: 2, name: 'Female' },
  ],
  deeplinkType: [
    { value: 'none', name: 'None' },
    { value: 'screen', name: 'Screen' },
    { value: 'webUrl', name: 'Web Url' },
  ],
  freezeTypeOption: [
    { value: 'paid_in_full', name: 'Paid in Full' },
    { value: 'monthly_payment', name: 'Monthly Payment' },
  ],
  periodInMonthOption: () => {
    const converted = [];
    for (let index = 1; index <= 12; index++) {
      converted.push({ value: index, name: `${index} ${index > 1 ? 'Months' : 'Month'}` });
    }
    return converted;
  },
  bankTransferOption: [
    { value: 'bca', name: 'BCA', imageSource: Images.imgVABCA },
    { value: 'mandiri', name: 'Mandiri', imageSource: Images.imgVAMandiri },
    { value: 'bri', name: 'BRI', imageSource: Images.imgVABRI },
    { value: 'bni', name: 'BNI', imageSource: Images.imgVABNI },
    { value: 'permata', name: 'Permata', imageSource: Images.imgVAPermata },
  ],
  membershipActionOptions: [
    { value: membershipActionCode.stopRecurring, name: 'Stop Recurring' }, // uncomment after final development
    { value: membershipActionCode.freeze, name: 'Freeze' },
    { value: membershipActionCode.unfreeze, name: 'Unfreeze' },
    { value: membershipActionCode.renew, name: 'Renew' }, // uncomment after final development
    { value: membershipActionCode.changePayment, name: 'Update Recurring Payment' },
    { value: membershipActionCode.viewDetails, name: 'View Details' },
  ],
  membershipRequestType: [
    { value: 'freeze_request', name: 'Freeze' },
    { value: 'purchase_request', name: 'Purchase' },
    // { value: 'stop_request', name: 'Stop' }, // uncomment after final development
  ],
  membershipRequestTypeStatus: [
    { value: 3, name: 'Review' },
    { value: 0, name: 'Decline' },
    { value: 10, name: 'Completed' },
  ],
  reasonOption: [
    { value: 'medical', name: 'Medical' },
    { value: 'pregnant', name: 'Pregnant' },
    { value: 'others', name: 'Others' },
  ],
  paymentPlanOption: [
    { value: 'paid_in_full', name: 'Full Payment' },
    { value: 'first_and_last_month_fee', name: 'Deposit' },
    { value: 'installment', name: 'Installment' },
  ],
  paymentMethodOption: [
    { value: 'bank_transfer', name: 'Virtual Account' },
    { value: 'credit_card', name: 'Credit Card' },
    { value: 'edc', name: 'EDC' },
    { value: 'point_booster', name: 'Membership' },
    { value: 'mark_as_paid', name: 'Mark As Paid' },
    { value: 'fit_point', name: 'Fit Point' },
    { value: 'point_booster', name: 'Point Booster' },
    { value: 'injection_manual', name: 'InjectionManual' },
    { value: 'giveaway', name: 'Free' }, // Giveaway
    { value: 'akulaku', name: 'AkuLaku' },
    { value: 'kredivo', name: 'Kredivo' },
    { value: 'gopay', name: 'GoPay' },
    { value: 'ovo', name: 'OVO' },
    { value: 'dana', name: 'DANA' },
    { value: 'linkaja', name: 'LinkAja' },
    { value: 'shopeepay', name: 'ShopeePay' },
    { value: 'cash', name: 'Cash' },
    { value: 'grabfood', name: 'GrabFood' },
    { value: 'gofood', name: 'GoFood' },
    { value: 'shopeefood', name: 'ShopeeFood' },
    { value: 'qris', name: 'QRIS' },
    { value: 'xendit-invoices', name: 'Xendit Invoices' },
    { value: 'corporate_deal', name: 'Corporate Invoice' },
    { value: 'traveloka', name: 'Traveloka' },
    { value: 'tiket', name: 'Tiket.com' },
    { value: 'tokopedia', name: 'Tokopedia' },
    { value: 'blibli', name: 'BliBli' },
  ],
  categoryMoveOption: [
    { value: 'sport_clinic', name: 'Sport Clinic' },
    { value: 'gym', name: 'Gym' },
    { value: 'classes', name: 'Classes' },
    { value: 'pt_at_home', name: 'PT at Home' },
    { value: 'virtufit', name: 'VirtuFit 1 on 1' },
  ],
  productTypeForOrder: [
    { value: 'shop', name: 'Shop' },
    { value: 'eat', name: 'Eat' },
  ],
  productTypeForOrderEats: [
    { value: 'alacarte', name: 'A la Carte' },
    { value: 'packages', name: 'Package' },
    { value: 'atstore', name: 'At Store' },
  ],
  addressTypeOrder: [{ value: 'new_address', name: 'New Address' }],
  optionStatusPayment: [
    { value: null, name: 'All Status' },
    { value: 'cancelled', name: 'Cancelled' },
    { value: 'completed', name: 'Completed' },
    { value: 'refunded', name: 'Refunded' },
  ],
  optionTransactionSource: [
    { value: null, name: 'All Source' },
    { value: 'app', name: 'App' },
    { value: 'dashboard', name: 'Dashboard' },
    { value: 'web', name: 'Web' },
  ],
  optionOrderType: [
    { value: null, name: 'All Order Type' },
    { value: 'move', name: 'Move' },
    { value: 'shop', name: 'Shop' },
    { value: 'eat', name: 'Eat' },
    { value: 'medic', name: 'Medic' },
    { value: 'top_up_point', name: 'Top Up Point' },
    { value: 'fitpoint_membership', name: 'Fit Point Membership' },
    { value: 'merchant_membership', name: 'Merchant Membership' },
    { value: 'freeze_membership', name: 'Freeze Membership' },
  ],
  optionSearchType: [
    { value: 'name', name: 'Name' },
    { value: 'order_id', name: 'Order ID' }, // Order ID => FITCO's Order ID
    { value: 'order_no', name: 'Order Number' }, // Order Number => FITCO's Unique Order Number
    { value: 'external_order_id', name: 'W-Order ID' }, // W-Order ID => WooCommerce's Order ID
    { value: 'tracking_number', name: 'Tracking Number' },
    { value: 'category', name: 'Category' },
    { value: 'phone', name: 'Phone' },
    { value: 'email', name: 'Email' },
  ],
  optionSearchTypeOrderSummary: [
    { value: 'product_name', name: 'Name' },
    { value: 'product_id', name: 'Product ID' },
    { value: 'product_sku', name: 'SKU' },
  ],
  campaignStatus: [
    { value: 0, name: 'Campaign' },
    { value: 1, name: 'Draft' },
  ],
  inboxStatus: [
    { value: 0, name: 'Inbox' },
    { value: 1, name: 'Draft' },
  ],
  orderStatus: [
    { value: 'draft', name: 'Draft' },
    { value: 'pending', name: 'Pending' },
    { value: 'completed', name: 'Completed' },
  ],
  orderShipmentStatus: [
    { value: 'processing', name: 'Processing' },
    { value: 'shipment', name: 'On Shipment' },
    { value: 'delivered', name: 'Delivered' },
    { value: 'delivery_report', name: 'Delivery Report' },
  ],
  segmentOption: [{ value: 3, name: 'All' }],
  publishOption: [
    { value: true, name: 'Right Now' },
    { value: false, name: 'In the Future' },
  ],
  deviceTabs: [
    { value: 'android', name: 'Android' },
    { value: 'ios', name: 'IOS' },
  ],
  mediaOption: [
    { value: 'images', name: 'Image' },
    { value: 'video', name: 'Video' },
  ],
  shippingMethod: [
    { value: 'jne', name: 'JNE' },
    { value: 'jnt', name: 'J&T' },
    { value: 'deliveree', name: 'Deliveree' },
    { value: 'own_courier', name: 'FITCO Courier' },
    { value: 'karyati', name: 'Karyati' },
    { value: 'dakota', name: 'Dakota' },
    { value: 'bramas', name: 'Bramas' },
    { value: 'bravo', name: 'Bravo' },
    { value: 'karunia_indah_delapan', name: 'Karunia Indah Delapan' },
    { value: 'aero_logistic', name: 'Aero Logistic' },
    { value: 'selaras', name: 'Selaras' },
    { value: 'afro', name: 'Afro' },
    { value: 'maju_bersama', name: 'Maju Bersama' },
    { value: 'aisy_sae_express', name: 'Aisy Sae Express' },
  ],
  sessionTypeOption: [
    { value: 'appointment', name: 'Appointment' },
    { value: 'conducted', name: 'Conducted' },
    { value: 'cancelled', name: 'Cancelled' },
  ],
  purchaseOrderTab: [
    { value: 'purchase_order', name: 'Purchase Order' },
    { value: 'delivery', name: 'Delivery' },
    { value: 'invoices', name: 'Invoices' },
    { value: 'completed', name: 'Completed' },
  ],
  PurchaseOrderStatusDelivery: [
    { value: 2, name: 'Processing' },
    { value: 5, name: 'On Shipment' },
  ],
  PurchaseOrderStatusInvoice: [
    { value: 6, name: 'Delivered' },
    { value: 7, name: 'Invoicing' },
    { value: 9, name: 'Awaiting Payment' },
  ],
  purchaseOrderStatusPOCompleted: [
    { value: 10, name: 'Completed' },
    { value: -1, name: 'Deleted' },
  ],
  PurchaseOrderActionOptions: [
    { value: 'edit', name: 'Edit' },
    { value: 'delete', name: 'Delete' },
    { value: 'proforma_invoice', name: 'Print Proforma Invoice' },
    { value: 'delivery_slip', name: 'Print Delivery Slip' },
    { value: 'invoice', name: 'Print Invoice' },
  ],
  labStatus: [
    { value: 'new', name: 'New' },
    { value: 'in_progress', name: 'In Progress' },
    { value: 'completed', name: 'Completed' },
  ],
  mealDeliveryStatus: [
    { value: 'summary', name: 'Summary' },
    { value: 'details', name: 'Details' },
  ],
  getOrderTypeDescription(value) {
    switch (value) {
      case 'move':
        return 'Move';
      case 'eat':
        return 'Eat';
      case 'shop':
        return 'Shop';
      case 'top_up_point':
        return 'Top-up FIT Points';
      case 'merchant_membership':
        return 'Membership';
      case 'fitpoint_membership':
        return 'FIT Points Membership';
      case 'freeze_membership':
        return 'Freeze Membership';
      default:
        return CommonHelper.renameFirstUpperCase(value);
    }
  },
  eatSessionType: [
    { value: 'lunch', name: 'Lunch' },
    { value: 'dinner', name: 'Dinner' },
    { value: 'extra', name: 'Extra' },
  ],
  eatSessionTime: [
    { value: null, name: 'All Meal Time' },
    { value: 'lunch', name: 'Lunch' },
    { value: 'dinner', name: 'Dinner' },
  ],
  pointBoosterActionOption: [
    { value: 'renew', name: 'Re new' },
    { value: 'purchase', name: 'Purchase' },
  ],
  paymentTermOption: [
    { value: 1, name: '1 Month' },
    { value: 3, name: '3 Months' },
    { value: 6, name: '6 Months' },
    { value: 12, name: '12 Months' },
  ],
  orderBookActionOptions: [{ value: 'cancel', name: 'Cancel' }],
  pickUpOption: [
    { value: null, name: 'All Item' },
    { value: true, name: 'Store only' },
    { value: false, name: 'Apps only' },
  ],
  eatActionOptions: [
    { value: 'edit', name: 'Edit' },
    { value: 'delete', name: 'Delete' },
  ],
  promoTypeOption: [
    { value: 1, name: 'Discount' },
    { value: 2, name: 'Cashback' },
  ],
  optionPatientStatus: [
    { value: null, name: 'All Patient Status' },
    { value: 'checked_in', name: 'Checked-in Only' },
    { value: 'checked_out', name: 'Checked-out Only' },
  ],
  titleName: [
    { value: 'Mr.', name: 'Mr.' },
    { value: 'Mrs.', name: 'Mrs.' },
    { value: 'Ms.', name: 'Ms.' },
  ],
  mealTimeOption: [
    { value: 'breakfast', name: 'Breakfast' },
    { value: 'morning-snack', name: 'Morning Snack' },
    { value: 'lunch', name: 'Lunch' },
    { value: 'afternoon-snack', name: 'Afternoon Snack' },
    { value: 'dinner', name: 'Dinner' },
  ],
  termOfPaymentOption: [
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
    { value: 4, name: '4' },
  ],
  membershipFilterOption: [
    { value: null, name: 'All Status' },
    { value: 'active', name: 'Active' },
    { value: 'inactive', name: 'Inactive' },
  ],
  appointmentStatusOption: [
    { value: null, name: 'All Status' },
    { value: 'app', name: 'App' },
    { value: 'dashboard', name: 'Dashboard' },
    { value: 'web', name: 'Web' },
  ],
  categoryMedicOption: [{ value: 'consultation', name: 'Consultation' }],
  consultationBookingStatusOption: [
    { value: null, name: 'All Status' },
    { value: 'completed', name: 'Completed' },
    { value: 'cancelled', name: 'Cancelled' },
  ],
  dateFilterStatusOption: [
    { value: null, name: 'All Date' },
    { value: 'specific_date', name: 'By Date' },
  ],
  idTypeOption: [
    { value: 'ktp', name: 'Kartu Tanda Penduduk (KTP)' },
    { value: 'passport', name: 'Passport' },
    { value: 'kia', name: 'Kartu Identitas Anak (KIA)' },
  ],
  religionOption: [
    { value: 'islam', name: 'Islam' },
    { value: 'kristen', name: 'Kristen' },
    { value: 'katolik', name: 'Katolik' },
    { value: 'budha', name: 'Budha' },
    { value: 'hindu', name: 'Hindu' },
  ],
  maritalStatusOption: [
    { value: 'single', name: 'Belum Kawin' },
    { value: 'married', name: 'Kawin' },
    { value: 'widowed', name: 'Janda / Duda' },
  ],
  resultStatus: [
    { value: 'positive', name: 'Positive' },
    { value: 'negative', name: 'Negative' },
  ],
};

const ScheduleStatus = {
  Active: 10,
  waiting: 3,
  Inactive: 0,
  Deleted: -1,
};

const GlobalCodeStatus = {
  Active: 10,
  Inactive: 0,
  Deleted: -1,
};

const membershipRequestTypeCode = {
  freeze: 'freeze_request',
  purchase: 'purchase_request',
  stop: 'stop_request',
};

const membershipRequestTypeStatusCode = {
  cancel: -1,
  review: 3,
  decline: 0,
  complete: 10,
};

const promoTypeCode = {
  Discount: 1,
  Cashback: 2,
};

const eatSessionTypeCode = {
  lunch: 'lunch',
  dinner: 'dinner',
  extra: 'extra',
};

const mealTimeTypeCode = {
  lunch: 'LUNCH',
  dinner: 'DINNER',
  lunchAndDinner: 'LUNCH & DINNER',
};

const productTypeForOrderCode = {
  shop: 'shop',
  eat: 'eat',
  move: 'move',
  merchantMembership: 'merchant_membership',
  medic: 'medic',
};

const paymentMethodOptionCode = {
  bank_transfer: 'bank_transfer',
  edc: 'edc',
  credit_card: 'credit_card',
  mark_as_paid: 'mark_as_paid',
  membership: 'point_booster',
  fit_point: 'fit_point',
  point_booster: 'point_booster',
  injection_manual: 'injection_manual',
  corporate_deal: 'corporate_deal',
  giveaway: 'giveaway',
  akulaku: 'akulaku',
  kredivo: 'kredivo',
  gopay: 'gopay',
  ovo: 'ovo',
  dana: 'dana',
  linkaja: 'linkaja',
  shopeepay: 'shopeepay',
  cash: 'cash',
  grabfood: 'grabfood',
  gofood: 'gofood',
  shopeefood: 'shopeefood',
  qris: 'qris',
  xendit_invoice: 'xendit-invoices',
  traveloka: 'traveloka',
  tiket: 'tiket',
  tokopedia: 'tokopedia',
  blibli: 'blibli',
};

const pointBoosterActionOptionCode = {
  purchase: 'purchase',
  renew: 'renew',
};

const statusPaymentCode = {
  Cancelled: -1,
  Awaiting_Token: 0,
  Token_Generated: 1,
  Virtual_Bank_Account_Generated: 2,
  Credit_Card_Charged: 3,
  Expired: 4,
  Pending: 5,
  Declined: 6,
  Suspended: 7,
  Confirmation: 8,
  Refund: 9,
  Complete: 10,
  Settlement: 11,
  GoPay_QR_And_Deeplink_Generated: 12,
};

const statusPaymentCodeV2 = {
  Expired: 'expired',
  Pending: 'pending',
  Complete: 'complete',
};

const orderBookActionCode = { Cancel: 'cancel', Complete: 'complete' };

const eatActionCode = { Delete: 'delete', Edit: 'edit' };

const paymentPlanCode = {
  paidInFull: 'paid_in_full',
  firstLastMonthFee: 'first_and_last_month_fee',
  installment: 'installment',
};
const purchaseOrderStatusCode = {
  newPO: 1,
  ProcessingPO: 2,
  OnShipmentPO: 5,
  DeliveredPO: 6,
  InvoicingPO: 7,
  AwaitingPaymentPO: 9,
  CompletedPO: 10,
  Canceled: -1,
};
const PurchaseOrderActionCode = {
  edit: 'edit',
  delete: 'delete',
  printProformaInvoice: 'proforma_invoice',
  printDeliverySlip: 'delivery_slip',
  printInvoice: 'invoice',
};
const orderStatusCode = {
  Draft: 0,
  Pending: 1,
  Confirm: 2,
  Processing: 3,
  Cancel: 4,
  OnShipment: 5,
  Refund: 9,
  Complete: 10,
  Reviewed: 11,
};

const radioButtonTrueFalseCode = {
  Yes: 1,
  No: 0,
};

const roomTypeCode = {
  VVIP: 'VVIP',
  VIP: 'VIP',
  Kelas1: 'Kelas 1',
  CVC: 'CVC',
};

const workoutCategoryProductCode = {
  Class: 'class',
  Coach: 'coach',
};

const serviceLocationCode = {
  AtHome: 'at_home',
  InStore: 'in_store',
  Virtual: 'virtual',
};

const healthStatusCode = {
  Unknown: 'unknown',
  HighRisk: 'high_risk',
  Risky: 'risky',
  Healthy: 'healthy',
};

export {
  ScheduleStatus,
  GlobalCodeStatus,
  MasterDataHelper,
  membershipRequestTypeCode,
  membershipRequestTypeStatusCode,
  promoTypeCode,
  eatSessionTypeCode,
  productTypeForOrderCode,
  paymentMethodOptionCode,
  pointBoosterActionOptionCode,
  statusPaymentCode,
  statusPaymentCodeV2,
  orderBookActionCode,
  membershipActionCode,
  paymentPlanCode,
  eatActionCode,
  purchaseOrderStatusCode,
  PurchaseOrderActionCode,
  radioButtonTrueFalseCode,
  mealTimeTypeCode,
  roomTypeCode,
  orderStatusCode,
  workoutCategoryProductCode,
  serviceLocationCode,
  healthStatusCode,
};
