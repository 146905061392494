import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// component
import { ModalInformationPopUp, ScanFace, SnackBarSimple } from '../../../../../components/Index';
// api
import {
  SelfieScanner,
  setSelfieImage,
  uploadFile,
  setFaceSimilarity,
} from '../../../../../services/api/UploadApi';
// helper
import { CommonHelper } from '../../../../../helpers/Index';
// style
import './ModalFaceScannerStyle.scss';

const optionToast = { vertical: 'top', horizontal: 'right' };

class ModalSelfieScanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  handleSubmitPhoto = async data => {
    const { selfieScannerApi, onClose, onSetFaceSimilarity } = this.props;
    try {
      const ocrImageSrc = JSON.parse(localStorage.getItem('ocrImageSrc-formattedbase64'));
      this.setState({ isSubmitting: true });
      const body = { first_file: ocrImageSrc, second_file: data };
      const response = await selfieScannerApi(body);
      if (response && response.data) {
        const similarity = response.data.similarity;
        if (similarity < 70) {
          this.processMessage(
            'Verifikasi Selfie dan FOTO Identitas gagal, lakukan pengecekan manual.',
            'error',
          );
        }
        onSetFaceSimilarity(similarity);
      }
      this.setState({ isSubmitting: false });
      onClose();
    } catch (error) {
      this.setState({ isSubmitting: false });
      this.processMessage('Please try again', 'error');
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { isSubmitting, toastInformation } = this.state;
    const { onClose, isOpen, onSetSelfieImage, onUploadFile } = this.props;

    const renderElement = (
      <Grid container direction="column" className="flex-wrap-unset container-modal-face-scanner">
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Selfie Scanner</h5>
            </Grid>
            <Grid item lg={1} md={1}>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <label className="text-regular text-center">Please align at the centre.</label>
        </Grid>
        <Grid item lg md>
          <ScanFace
            isSubmitting={isSubmitting}
            localStorageKey="selfieImageSrc"
            onSubmitPhoto={this.handleSubmitPhoto}
            onSetSelfieImage={onSetSelfieImage}
            onUploadFile={onUploadFile}
            type="selfie"
          />
        </Grid>
      </Grid>
    );

    return (
      <>
        <ModalInformationPopUp
          isOpen={isOpen}
          onClose={onClose}
          customElementProps={renderElement}
          size="medium"
        />
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selfieScannerApi: body => SelfieScanner(body),
  onSetSelfieImage: payload => setSelfieImage(dispatch, payload),
  onSetFaceSimilarity: payload => setFaceSimilarity(dispatch, payload),
  onUploadFile: body => uploadFile(body),
});

const mapStateToProps = () => ({});

ModalSelfieScanner.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSetFaceSimilarity: PropTypes.func,
  onSetSelfieImage: PropTypes.func,
  onUploadFile: PropTypes.func,
  selfieScannerApi: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelfieScanner);
