import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
// component
import { ModalInformationPopUp, ScanFace, SnackBarSimple } from '../../../../../components/Index';
// api
import { OCRScanner, setIdentityImage, uploadFile } from '../../../../../services/api/UploadApi';
// helper
import { CommonHelper } from '../../../../../helpers/Index';
// style
import './ModalFaceScannerStyle.scss';

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

class ModalFaceScanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
    };
  }

  handleSubmitPhoto = async data => {
    const { OCRScannerApi, onClose } = this.props;
    try {
      this.setState({ isSubmitting: true });
      const body = { file: data };
      await OCRScannerApi(body);
      this.setState({ isSubmitting: false });
      onClose();
      this.processMessage('scan KTP success, user data populated!', 'success');
    } catch (error) {
      this.setState({ isSubmitting: false });
      this.processMessage('Please try again', 'error');
    }
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  render() {
    const { isSubmitting, toastInformation } = this.state;
    const { onClose, isOpen, onSetIdentityImage, onUploadFile } = this.props;

    const renderElement = (
      <Grid container direction="column" className="flex-wrap-unset container-modal-face-scanner">
        <Grid item lg md className="section-header-modal">
          <Grid container justify="space-between">
            <Grid item lg={11} md={11}>
              <h5 className="wrapping-container second line">Image Scanner</h5>
            </Grid>
            <Grid item lg={1} md={1}>
              <div className="container-button-close">
                <IconButton onClick={onClose}>
                  <i className="ic-ffo-close container-icon-prefix size-21" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <label className="text-regular text-center">Please align at the centre.</label>
        </Grid>
        <Grid item lg md>
          <ScanFace
            isSubmitting={isSubmitting}
            localStorageKey="ocrImageSrc"
            onSubmitPhoto={this.handleSubmitPhoto}
            onSetIdentityImage={onSetIdentityImage}
            onUploadFile={onUploadFile}
            type="identity"
          />
        </Grid>
      </Grid>
    );

    return (
      <>
        <ModalInformationPopUp
          isOpen={isOpen}
          onClose={onClose}
          customElementProps={renderElement}
          size="medium"
        />
        <SnackBarSimple
          open={toastInformation.isOpen}
          durationHide={2000}
          message={toastInformation.message}
          onClickClose={this.handleCloseToast}
          snackbarType={toastInformation.snackbarType}
          anchor={optionToast}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  OCRScannerApi: body => OCRScanner(dispatch, body),
  onSetIdentityImage: payload => setIdentityImage(dispatch, payload),
  onUploadFile: body => uploadFile(body),
});

const mapStateToProps = () => ({});

ModalFaceScanner.propTypes = {
  isOpen: PropTypes.bool,
  OCRScannerApi: PropTypes.func,
  onClose: PropTypes.func,
  onSetIdentityImage: PropTypes.func,
  onUploadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFaceScanner);
