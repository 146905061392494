/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import clsx from 'clsx';
// Style
import './SelectInputSearchMainStyle.scss';

const { Option } = Select;

const SelectInputSearchMain = props => {
  const {
    onChange,
    options,
    currentValue,
    defaultValue,
    size,
    disabled,
    placeholder,
    validateStatus,
    errorMessage,
  } = props;

  const handleItemChange = selectedValue => {
    onChange(selectedValue);
  };

  const onBlur = () => {
    // console.log('blur');
  };

  const onFocus = () => {
    // console.log('focus');
  };

  const onSearch = val => {
    // console.log('search:', val);
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Select
        className={`container-select-input-search-main ${clsx({ [`text-${size}`]: size })}`}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={currentValue}
        defaultValue={defaultValue}
        size={size}
        style={{
          borderRadius: 20,
          width: '100%',
        }}
        disabled={disabled}
        onChange={handleItemChange}
      >
        {options
          ? options.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.name}
              </Option>
            ))
          : null}
      </Select>
    </Form.Item>
  );
};

SelectInputSearchMain.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

SelectInputSearchMain.defaultProps = {
  size: 'middle',
};

export default SelectInputSearchMain;
