import React from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Form } from 'antd';
import './UploadDocumentStyle.scss';
import ButtonMain from '../button-main/ButtonMain';
import { FileHelper } from '../../../helpers/Index';
// ENV
import { UPLOAD_URL } from '../../../environments/Environment';

const imageFormatJpeg = 'image/jpeg';
const imageFormatPng = 'image/png';
const formatPdf = 'application/pdf';
const formatWord = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const formatWordOld = 'application/msword';
const dummyUrl = UPLOAD_URL;

const UploadDocument = props => {
  const {
    errorMessage,
    fileList,
    fileType,
    labelText,
    maxFiles,
    maxSize,
    onFileChange,
    size,
    validateStatus,
  } = props;

  const [fileListState, setFileListState] = React.useState(fileList || '');
  const [isErrorState, setIsErrorState] = React.useState(false);

  React.useEffect(() => {
    const updateValue = async () => {
      setFileListState(fileList);
    };
    updateValue();
  }, [fileList]);

  const validationJpegOrPng = file => {
    const isJpgOrPng = file.type === imageFormatJpeg || file.type === imageFormatPng;
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const validationPdf = file => {
    const isPdf = file.type === formatPdf;
    if (!isPdf) {
      message.error('You can only upload PDF file!');
    }
    return isPdf;
  };

  const validationWord = file => {
    const isWord = file.type === formatWord || file.type === formatWordOld;
    if (!isWord) {
      message.error('You can only upload Word file!');
    }
    return isWord;
  };

  const validationSize = file => {
    const checkOversize = file.size / 1024 / 1024 < maxSize;
    if (!checkOversize) {
      message.error('Image must smaller than 2MB!');
    }
    return checkOversize;
  };

  const handleChange = info => {
    if (!isErrorState || info.file.status === 'removed') {
      let modifyFileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      const checkOverUploaded = info.fileList.length > maxFiles;
      modifyFileList = checkOverUploaded ? modifyFileList.slice(0, maxFiles) : modifyFileList;
      // 2. Read from response and show file link
      modifyFileList = modifyFileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.url || file.response.url; // suppose to be path url preload in s3
        }
        return file;
      });

      if (info.fileList.length === 0) {
        onFileChange([]);
      }

      setFileListState(modifyFileList);

      if (info.file.status !== 'uploading') {
        // eslint-disable-next-line no-console
        console.log(info.file, info.fileList);
      }

      if (info.file.status === 'uploading') {
        if (checkOverUploaded) {
          message.error(`Can only upload Maximum ${maxFiles} Files`);
        }
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);

        modifyFileList.forEach(item => {
          if (item.uid === info.file.uid) {
            FileHelper.getBase64(info.file.originFileObj, base64file => {
              item.base64file = base64file;
              onFileChange(modifyFileList);
            });
          }
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const validatePdfOrImg = file => {
    let passCheck = false;

    if (file.type === formatPdf) {
      passCheck = validationPdf(file);
    }
    if (file.type === imageFormatJpeg || file.type === imageFormatPng) {
      passCheck = validationJpegOrPng(file);
    }

    if (!passCheck) {
      message.error('You can only upload PDF/JPG/PNG file!');
    }

    return passCheck;
  };

  const validatePdfOrImgOrWord = file => {
    let passCheck = false;

    if (file.type === formatPdf) {
      passCheck = validationPdf(file);
    }
    if (file.type === imageFormatJpeg || file.type === imageFormatPng) {
      passCheck = validationJpegOrPng(file);
    }
    if (file.type === formatWord || file.type === formatWordOld) {
      passCheck = validationWord(file);
    }

    if (!passCheck) {
      message.error('You can only upload PDF/JPG/PNG/WORD file!');
    }

    return passCheck;
  };

  const beforeUpload = file => {
    setIsErrorState(false);

    let passCheck = false;

    if (fileType === 'pdf/img') {
      passCheck = validatePdfOrImg(file);
    }

    if (fileType === 'img') {
      passCheck = validationJpegOrPng(file);
    }

    if (fileType === 'pdf') {
      passCheck = validationPdf(file);
    }

    if (fileType === 'pdf/img/doc') {
      passCheck = validatePdfOrImgOrWord(file);
    }

    if (!validationSize(file)) {
      setIsErrorState(true);
    }

    if (!passCheck) {
      setIsErrorState(true);
    }

    return passCheck;
  };

  const handleOnPreview = file => {
    // let previewFile = file.url;
    const previewFile = file.url;

    if (!previewFile.includes('https')) {
      // previewFile = FileHelper.dataURLtoBlob(file.url, file.name);
      // const fileURL = URL.createObjectURL(previewFile);
      // window.open(fileURL, '_blank');
      // window.open(previewFile, '_blank');
    } else {
      window.open(previewFile, '_blank');
    }
  };

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <div className="container-upload-document">
        <Upload
          name="file"
          action={dummyUrl}
          onChange={handleChange}
          headers={{
            authorization: 'authorization-text',
          }}
          multiple={false}
          fileList={fileListState}
          beforeUpload={beforeUpload}
          onPreview={handleOnPreview}
        >
          <ButtonMain type="ghost" size={size} labelText={labelText} />
        </Upload>
      </div>
    </Form.Item>
  );
};

UploadDocument.propTypes = {
  errorMessage: PropTypes.string,
  fileList: PropTypes.array,
  fileType: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  onFileChange: PropTypes.func,
  size: PropTypes.string,
  validateStatus: PropTypes.string,
};

UploadDocument.defaultProps = {
  size: 'xl',
  maxFiles: 1,
  maxSize: 2,
};

export default UploadDocument;
